module config {
    interface IInterceptorResult {
        status: number,
        data?: any
    }

    export class appConfigInterceptor implements ng.IHttpInterceptor {

        static $inject = ['$q', '$location', '$cookies', '$rootScope','ENV'];

        constructor(private $q: ng.IQService, private $location: ng.ILocationService, private $cookies: ng.cookies.ICookiesService,
            private $rootScope: interfaces.applicationcore.IRootScope, private ENV: interfaces.applicationcore.serverConfig
        ) {
        }

        public request = (config: ng.IRequestConfig) => {
            if (!(this.$cookies.get("userToken") && this.$cookies.get("userFullName")))
                config.headers["Authorization"] = "";

            var token = this.$cookies.get("userToken");
            if (token && this.$cookies.get("userFullName")) {
                var tokenDate: moment.Moment = undefined;
                //Get the exp date and username.
                const splitStrings = token.split(".");
                if (splitStrings.length > 0)
                {
                    var decodedString = atob(splitStrings[1]);
                    var part = angular.fromJson(decodedString);
                    tokenDate = moment.unix(part.exp).utc();
                }

                if (tokenDate) {
                    if (tokenDate.isBefore(moment().utc().add(1, 'minutes'))) {
                        this.$location.url("/login");
                    }
                }
            }

            if (this.ENV.WINDOWS_AUTHENTICATION){
                config.withCredentials = true;
            }
            else{
                config.withCredentials = false;
            }

            return config;
        };

        public response = (result: ng.IHttpResponse<any>) => {
            if (angular.isString(result.data))
            {
                if (result.data)
                {
                    if (result.data.indexOf('modal') >= 0) {
                        // Found modal
                        return result;
                    }
                }
            }

            //////////////////////////////
            var token = this.$cookies.get("userToken");
            if (token && this.$cookies.get("userFullName")) {
                var tokenDate: moment.Moment = undefined;
                //Get the exp date and username.
                const splitStrings = token.split(".");
                if (splitStrings.length > 0)
                {
                    var decodedString = atob(splitStrings[1]);
                    var part = angular.fromJson(decodedString);
                    tokenDate = moment.unix(part.exp).utc();
                }

                if (tokenDate) {
                    if (tokenDate.isBefore(moment().utc().add(10, 'minutes'))) {
                        this.$rootScope.hasBeenActive = true;
                        return result;
                    }
                }
            }
            
            return result;
        }

        public responseError = (result: IInterceptorResult) => {
            if (result.status === 401 || result.status === 403) {
                if (!this.$rootScope.previousPath) {
                    this.$rootScope.previousPath = this.$location.absUrl();
                }
                this.$location.url("/login");

                var message = {
                    LocaleDescription: <string>result.data.Message,
                    TypeEnum: 1
                };

                this.$rootScope.Messages = [<interfaces.applicationcore.IMessageItem>message];
            }

            if (!result.data || result.status === -1) {
                result.data = {
                    error: "Unkown Error"
                }
                var message = {
                    LocaleDescription: "Connection Error",
                    TypeEnum: 1
                };
                this.$rootScope.Messages = [<interfaces.applicationcore.IMessageItem>message];
            }

            return this.$q.reject(result);
        }
    }

    export class appResourceDateInterceptor {
        private static regexIso8601 = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

        public static convertDateStringsToDates(input) {
            // Ignore things that aren't objects.
            if (typeof input !== "object") return input;

            for (var key in input) {
                if (!input.hasOwnProperty(key)) continue;
               
                var value = input[key];
                var match;
                // Check for string properties which look like dates.
                if (typeof value === "string" && (match = value.match(appResourceDateInterceptor.regexIso8601)) && value.length >= 10) {

                    var milliseconds = Date.parse(match[0])
                    if (!isNaN(milliseconds)) {
                        input[key] = moment.utc(moment(milliseconds).format("YYYY-MM-DDTHH:mm:ss"));
                    }

                } else if (typeof value === "object") {
                    // Recurse into object
                    appResourceDateInterceptor.convertDateStringsToDates(value);
                }
            }
        }

        public static parseResponseDates(response) {
            if (!angular.isArray(response.resource))
            {
                appResourceDateInterceptor.convertDateStringsToDates(response.resource);
            }
            else
            {
                angular.forEach(response.resource, function (o) {
                    appResourceDateInterceptor.convertDateStringsToDates(o);
                });
            }
            
            return response.resource;
        }
    }

    export class appResourceNVD3Interceptor {
        public static mapXY(option: interfaces.applicationcore.INVD3Chart) {

            switch (option.type) {
                case "lineChart":
                    option["x"] = (d) => {
                        return d.x;
                    };

                    option["y"] = (d) => {
                        return d.y;
                    };

                    option["yAxis"]["tickFormat"] = (d) => {
                        return d3.format('.02f')(d);
                    };

                    break;
                case "discreteBarChart":
                    option["x"] = (d) => {
                        return d.label;
                    };

                    option["y"] = (d) => {
                        return d.value;
                    };

                    option["valueFormat"] = (d) => {
                        return d3.format(',.4f')(d);
                    };

                    break;
                case "multiBarChart": case "multiBarHorizontalChart" :
                    option["x"] = (d) => {
                        return d.label;
                    };

                    option["y"] = (d) => {
                        return d.value;
                    };
                     
                    break;

                    
                default:
                    option["x"] = (d) => {
                        return d.key;
                    };

                    option["y"] = (d) => {
                        return d.y;
                    };
                    break;
            }
        }

        public static parseResponseOptions(response) {
            if (!angular.isArray(response.resource)) {
                appResourceNVD3Interceptor.mapXY(response.resource.Options.chart);
            }
            else {
                angular.forEach(response.resource, function (o) {
                    appResourceNVD3Interceptor.mapXY(o.Options.chart);
                });
            }

            if (!angular.isArray(response.resource)) {
                appResourceDateInterceptor.convertDateStringsToDates(response.resource);
            }
            else {
                angular.forEach(response.resource, function (o) {
                    appResourceDateInterceptor.convertDateStringsToDates(o);
                });
            }

            return response.resource;
        }
    }

    angular.module("app").service("appConfigInterceptor", config.appConfigInterceptor);
}